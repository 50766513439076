<template>
  <div class="article-box">
    <p class="leftP">
      <span @click="$router.go(-2)" style="color: #409EFF;cursor: pointer;">学校列表</span> /
      <span @click="$router.go(-1)" style="color: #409EFF;cursor: pointer;">年级列表</span> /
      <span>班级列表</span>
    </p>
    <el-button class="button" type="primary" @click="addClass()">添加班级</el-button>
    <el-table :data="classData" style="width: 100%" v-loading="loading">
      <el-table-column prop="ID" label="班级ID" align="center"></el-table-column>
      <el-table-column prop="ClassName" label="班级名称" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="seachStudent(scope.row)">查看学生</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加班级" :visible.sync="dialogFormVisible" width="500px">
      <el-form :model="form" :inline="true">
        <el-form-item label="班级名称：">
          <el-input v-model="form.className" style="width: 200px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    classList,
    classAdd
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        levelId: this.$route.params.levelId, //年级id
        schoolId: this.$route.params.schoolId, //学校id
        classData: [], // 班级列表
        dialogFormVisible: false,
        form: {}, //表单
        loading: false, //加载框
      }
    },
    mounted() {
      this.classList() // 获取班级列表
    },
    methods: {
      addClass() { //点击添加班级按钮
        this.form = {}
        this.dialogFormVisible = true
      },
      seachStudent(item) { //点击查看学生按钮
        this.$router.push({
          name: "studentList",
          params: {
            schoolId: this.schoolId,
            levelId: this.levelId,
            classId: item.ID
          }
        })
      },
      add() { //添加班级弹框添加按钮
        if (!this.form.className) {
          this.$message.warning("班级名称不能为空")
        } else {
          const data = {
            school: this.schoolId,
            className: this.form.className,
            classType: this.levelId
          }
          classAdd(data).then(res => { //发送添加班级请求
            if (res.status.code == 1) {
              this.$message.success("班级添加成功！")
              this.dialogFormVisible = false //关闭添加班级弹框
              this.classList() //刷新班级列表
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error("发生未知错误，请再试一次！")
          })
        }
      },
      classList() { // 班级列表
        this.loading = true
        const data = {
          classType: this.levelId
        }
        classList(data).then(res => {
          this.classData = res.data // 班级列表
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
</script>
<style scoped>
  .leftP {
    float: left;
    text-align: left;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 5vh;
    display: inline-block;
    color: gray;
  }

  .button {
    float: right;
    margin-right: 2vw;
  }
</style>
